html {
  min-height: 100%;
  height: 100%;
  overflow-x: hidden;
}

body {
  background: #000;
  margin: 0;
  padding: 0;
  font-family: Raleway, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 400;
}

header {
  text-align: center;
}

header .logo {
  height: 32px;
  box-sizing: content-box;
}

.content {
  text-align: center;
  z-index: 11;
  height: 260px;
  margin: auto;
  position: absolute;
  inset: 0;
}

.content h1 {
  color: #fff;
  margin: 0 1vw;
  font-family: Baloo Bhaijaan, serif;
  font-size: 4em;
  font-weight: 400;
  position: relative;
}

.content h2 {
  color: #fff;
  margin-bottom: 50px;
  font-family: Turret Road, serif;
  font-size: 2.2em;
  font-weight: 500;
}

.content a {
  color: #fff;
  border: 2px solid #fff;
  border-radius: 50px;
  padding: 12px 19px;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  display: inline-block;
}

.content a:hover {
  opacity: .8;
}

#starfield {
  min-height: 100%;
  width: 100%;
  z-index: 0;
  pointer-events: none;
  margin: 0 0 -6px;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

#starfield canvas {
  margin: 0;
  padding: 0;
}

div#full-logo {
  margin-bottom: 20px;
}

#countdown {
  height: 100px;
  width: 50%;
  margin: 2vh auto 4vh;
  top: 50%;
  left: 0;
  right: 0;
}

#countdown.show {
  display: block;
}

#countdown > div {
  float: left;
  width: 25%;
  letter-spacing: 2px;
  text-align: center;
  color: #ccc;
  font-family: Baloo Bhaijaan, serif;
  font-size: 4vw;
  font-weight: 100;
  line-height: .8em;
  position: relative;
}

#countdown > div > span {
  letter-spacing: 0;
  color: #777;
  text-transform: lowercase;
  margin-top: 20px;
  font-size: 1.2vw;
  font-weight: 100;
  line-height: 1.2em;
  display: block;
}

@media (max-width: 480px) {
  #countdown {
    width: 100%;
  }

  #countdown > div {
    width: 25%;
    letter-spacing: 2px;
    font-size: 30px;
    font-weight: 700;
    line-height: .8em;
  }

  #countdown > div > span {
    font-size: 11px;
  }
}

.footer {
  width: 100%;
  height: 40px;
  color: #888;
  text-align: right;
  margin-top: 325px;
  padding: 10px 10px 0;
  font-size: 10px;
  line-height: 1.8em;
  bottom: 0;
}

/*# sourceMappingURL=index.33dc4648.css.map */
